import React from 'react';
import { useState } from 'react';
import "./services.css";

const Services = () => {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    }
  return (
    <section className="services section" id="services">
          <h2 className="section__title">Services</h2>
        <span className="section__subtitle">Write me your project, I'll get back to you with a well-structured plan. You like it, you pay after. < br/> Complex projects (time or type of technologies being used) need 50% payment down.<br/> <br/>Here's what I offer. </span>

        <div className="services__container container grid">
            <div className="services__content">
                <div>
                    <i className="uil uil-web-grid services__icon"></i>
                    <h3 className="services__tile"> Front-end <br/> Product Designer</h3>
                </div>

                <span className="services__button" onClick={() =>toggleTab(1)}>
                View More <i className="uil uil-arrow-right services__button-icon"></i></span>
            
            <div className={toggleState === 1 ? "services__modal active-modal " : "services__modal"}>
                <div className="services__modal-content">
                    <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
               
                <h3 className="h3 services__modal-title">Front-end/ Product Designer</h3>
                <p className="services__modal-desciption">  Professional modern static or dynamic pages, e-commerce boost. Do you want better results online?
                </p>

                <ul className="services__modal-services grid">
                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info"> HTML/CSS/JS Or Mordern React pages</p>
                    </li>

                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info"> SEO.</p>
                    </li>

                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info">Github/GIT</p>
                    </li>

                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info"> React</p>
                    </li>

                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info"> Canva/Photoshop products for compagnies.</p>
                    </li>
                </ul> 
                <p style={{color: "green"}} className="services__modal-desciption">  Prices may vary based on the project. </p>            
                </div>
            </div>
            </div>

            <div className="services__content">
                <div>
                    <i className="uil uil-arrow services__icon"></i>
                    <h3 className="services__tile"> Back-end  <br/> Development</h3>
                </div>

                <span  onClick={() =>toggleTab(2)} className="services__button">View More <i className="uil uil-arrow-right services__button-icon"></i></span>
            
            <div className={toggleState === 2 ? "services__modal active-modal " : "services__modal"}>
                <div className="services__modal-content">
                    <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
               
                <h3 className="h3 services__modal-title"> Back-end  </h3>
                <p className="services__modal-desciption"> Are you tired of using online themes that you are unsure about backend security? 
                </p>

                <ul className="services__modal-services grid">
                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info">ASP.NET Core C# /AWS micro services</p>
                    </li>

                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info"> Databases</p>
                    </li>

                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info">Authenfication/cosuming Rest API</p>
                    </li>

                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info"> Web security/Hashing</p>
                    </li>

                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info"> Payments/Stripe</p>
                    </li>
                </ul>
                <p style={{color: "green"}} className="services__modal-desciption">  Prices may vary based on the project. </p>            
                </div>
            </div>
            </div>

            <div className="services__content">
                <div>
                    <i className="uil uil-edit services__icon"></i>
                    <h3 className="services__tile">Digital <br/> Marketing </h3>
                </div>

                <span onClick={() =>toggleTab(3)} className="services__button">View More <i className="uil uil-arrow-right services__button-icon"></i></span>
            
            <div className={toggleState === 3 ? "services__modal active-modal " : "services__modal"}>
                <div className="services__modal-content">
                    <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
               
                <h3 className="h3 services__modal-title">Digital Marketing</h3>
                <p className="services__modal-desciption"> Do you want your business to gain popularity? Do you know how your competitors are running you out of business?
                </p>

                <ul className="services__modal-services grid">
                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info"> Google Ads</p>
                    </li>

                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info"> Facebook/Intagram/Snapchat Ads</p>
                    </li>

                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info">Email Marketing</p>
                    </li>

                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info"> Pinterest/Twitter/TikTok</p>
                    </li>

                    <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p className="services__modal-info">Business analytics</p>
                    </li>
                </ul>
                <p style={{color: "green"}} className="services__modal-desciption">  Prices may vary based on the project. </p>            
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default Services