import React from "react";
import "./footer.css";
import cash from "../../assets/Cashapp.jpeg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Spinoza Delva</h1>

        <ul className="footer__list">
          <li>
            <a href="#about" className="footer__link">
              About
            </a>
          </li>

          <li>
            <a href="#services" className="footer__link">
              Services
            </a>
          </li>

          <li>
            <a href="#contact" className="footer__link">
              contact
            </a>
          </li>
        </ul>

        <div className="footer__social">
          <a
            href="https://www.linkedin.com/in/spinozadelva/"
            className="footer__social-link"
            target="_blank"
          >
            <i class="bx bxl-linkedin"></i>
          </a>
          <a
            href="https://github.com/SpinozaDelva"
            className="footer__social-link"
            target="_blank"
          >
            <i class="bx bxl-github"></i>
          </a>
        </div>

        <p className="footer__description">
          This modern simple project was made in react.js, visit my Github and
          get familiar with my coding style.
          <br />
          "Why choose me? With a Computer Programming & IT degree from ASA
          College and current enrollment in Computer Engineering at Hofstra
          University, I offer swift solutions to your tech challenges. Skip the
          online search and describe your project to me – I'll provide quick
          fixes within 48 hours, backed by constant communication and support.
          Call me at 917-808-7320."
        </p>
        <span className="footer__copy">
          Previous portfolio for sale <br />
          More updates coming!!
          <br />
          <br />
          &#169; Spinoza. All rights reserved
        </span>
      </div>
    </footer>
  );
};

export default Footer;
