import React from 'react';
import { useState } from 'react';
import "./qualification.css"


const Qualification = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

  return (
    <section className="qualification section">
             <h2 className="section__title">Qualification</h2>
        <span className="section__subtitle">My personal journey</span>

        <div className="qualification__container container">
            <div className="qualification__tabs">
                <div className={toggleState === 1 ? "qualification__button qualification__active button--flex" 
                : "qualification__button button--flex"}
                onClick ={() => toggleTab(1)}
                >    
                    <i className="uil uil-graduation-cap qualification__icon"></i>
                    Education
                </div>

                <div className={toggleState === 2 ? "qualification__button qualification__active button--flex" 
                : "qualification__button button--flex"}
                onClick ={() => toggleTab(2)}
                >

                    <i className="uil uil-briefcase-alt qualification__icon brief"></i> Experience
                </div>
            </div>

            <div className="qualification__sections">
            <div className={toggleState === 1 ? "qualification__content qualification__content-active"
                    :"qualification__content"} 
                    >
                    <div className="qualification__data">
                        <div>
                            <h3 className="qualification__title">ASA College</h3> 
                            <span  className="qualification__subtitle">- Computer Programming -</span>
                            <span className="qualification__subtitle"> - Manhattan - </span>

                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"></i>
                                2018 - 2020
                            </div>
                        </div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                            <h3 className="qualification__title">Computer Engineering </h3>
                            <span className="qualification__subtitle">- Hofstra University -</span>
                            <span className="qualification__subtitle">- Long Island NY -</span>

                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"></i>
                                2020 - present
                            </div>
                        </div>

                        
                    </div>

   
               

                </div>

                <div className={toggleState === 2 ? "qualification__content qualification__content-active"
                    :"qualification__content"} 
                    >
                    <div className="qualification__data">
                        <div>
                            <h3 className="qualification__title">Software Development</h3>
                            <span className="qualification__subtitle"> - Automotion Parking -</span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"></i>
                                2022 - present
                            </div>
                        </div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>

                    <div className="qualification__data">
                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                            <h3 className="qualification__title">IT Administrator</h3>
                            <span className="qualification__subtitle"> Vox Media</span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"></i>
                                2021 - 2022
                            </div>
                        </div>

                        
                    </div>

                    <div className="qualification__data">
                        <div>
                            <h3 className="qualification__title">Computer Programming Intern</h3>
                            <span className="qualification__subtitle"> Harlem Mother S.A.V.E</span>
                            <div className="qualification__calender">
                                <i className="uil uil-calendar-alt"></i>
                                2019 - 2020
                            </div>
                        </div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    </section>

 
  )
}

export default Qualification